import { sha256 } from 'js-sha256';
import secureLocalStorage from 'react-secure-storage';
import {
  AuthenticationControllerApi,
  Configuration,
  RegisterRequest,
  RegisterRequestGenderEnum,
  RegisterRequestRoleEnum,
  UserControllerApi,
} from 'rest-client-sdk';
import { notify, notifyError } from 'toast';
import { create } from 'zustand';

interface AuthState {
  token: string | null;
  user: any | null;
  loginAction: (
    email: string,
    password: string,
    recaptchaToken: string,
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => Promise<void>;
  loginWith2FAAction: (
    email: string,
    password: string,
    otp: number,
    salt: string,
    csrfToken: string,
    recaptchaToken: string,
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => Promise<void>;
  refreshTokenAction: (
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => Promise<string>;
  updateUser: (data: any) => void;
  registerAction: (
    data: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      gender: string;
      phoneNumber: string;
      role: string;
      recaptchaToken: string;
    },
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => Promise<void>;
  logOut: (navigate: (route: string, state?: any) => void) => void;
}

interface AuthResponseData {
  refreshToken: string;
  token: string;
  id: string;
  role: string;
  requires2FA: boolean;
}

const routeByRole = (role: string) => {
  switch (role) {
    case 'ROLE_PATIENT':
      return '/patient/dashboard';
    case 'ROLE_PHYSICIAN':
      return '/physician/dashboard';
    case 'ROLE_ADMIN':
      return '/admin/dashboard';
    case 'ROLE_USER':
      return '/role/dashboard';
    default:
      return '/sign-in';
  }
};

export const useAuthStore = create<AuthState>((set, get) => ({
  token: secureLocalStorage.getItem('token')?.toString() || null,
  user: {
    ...JSON.parse(secureLocalStorage.getItem('user')?.toString() || '{}'),
  },
  updateUser: (data: any) =>
    set((state) => ({
      ...state,
      user: { ...state.user, ...data },
    })),
  refreshTokenAction: async (
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => {
    const authenticationApi = new AuthenticationControllerApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_API_URL}`,
      })
    );
    try {
      const refreshToken =
        secureLocalStorage.getItem('refreshToken')?.toString() || '';
      // Request a new token using the current refresh token
      const response = await authenticationApi.refreshToken({
        refreshToken: refreshToken,
      });

      if (response.data?.statusCode === 200 && response.data.data) {
        const newTokenData = response.data.data as AuthResponseData;
        const { token } = newTokenData;
        // Update state and secure storage with the new tokens
        set((state) => ({ ...state, token }));
        secureLocalStorage.setItem('token', token);
        secureLocalStorage.setItem('refreshToken', newTokenData.refreshToken);
        return token;
      } else {
        notifyError(translate('Session expired. Please log in again.'));
        get().logOut(navigate); // Log out if the refresh token is invalid
      }
    } catch (error) {
      console.error('Refresh token error:', error);
      notifyError(translate('Session expired. Please log in again.'));
      get().logOut(navigate); // Log out if an error occurs
    }
  },

  registerAction: async (
    data: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      gender: string;
      phoneNumber: string;
      role: string;
      recaptchaToken: string;
    },
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => {
    try {
      const authenticationApi = new AuthenticationControllerApi(
        new Configuration({
          basePath: `${process.env.REACT_APP_API_URL}`,
        })
      );
      // Generate salt for password hashing
      const salt = crypto
        .getRandomValues(new Uint8Array(16))
        .reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');

      const registerRequest: RegisterRequest = {
        email: data.email,
        password: sha256(data.password + salt),
        salt: salt,
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender as RegisterRequestGenderEnum,
        phoneNumber: data.phoneNumber,
        role: data.role as RegisterRequestRoleEnum,
        recaptchaToken: data.recaptchaToken,
      };

      const response = await authenticationApi.register(registerRequest);
      console.log('Registration response:', response);

      if (response.data?.statusCode === 200 && response.data?.data) {
        const authData = response.data.data as AuthResponseData;
        const { id, role } = authData;
        const { token } = authData;
        set((state) => ({ ...state, user: { id, role }, token }));

        secureLocalStorage.setItem('refreshToken', authData.refreshToken);
        secureLocalStorage.setItem('token', authData.token);
        secureLocalStorage.setItem(
          'user',
          JSON.stringify({
            id: authData.id,
            role: authData.role,
          })
        );

        notify(translate('Registration successful'));
        navigate(
          `${
            authData.role === 'ROLE_PATIENT' ? 'patient' : 'physician'
          }/dashboard`
        );
      } else {
        const errorMessage = response.data?.message || 'Registration failed';
        console.error('Registration failed:', errorMessage);
        notifyError(translate('Registration failed. Please try again.'));
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        'Registration failed. Please try again.';
      console.error(errorMessage);
      notifyError(translate('Registration failed. Please try again.'));
    }
  },

  loginAction: async (
    email: string,
    password: string,
    recaptchaToken: string,
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => {
    const authenticationApi = new AuthenticationControllerApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_API_URL}`,
      })
    );
    try {
      const initiateResponse = await authenticationApi.initiateLogin({ email });
      if (
        initiateResponse.data?.statusCode !== 200 ||
        !initiateResponse.data?.data
      ) {
        notifyError(translate('Login failed. Please check your credentials.'));
        return;
      }

      const { salt, csrfToken } = initiateResponse.data.data;
      const hashedPassword = sha256(password + salt);

      const authResponse = await authenticationApi.authenticate(csrfToken, {
        email,
        password: hashedPassword,
        csrfToken,
        recaptchaToken,
      });

      if (authResponse.data?.statusCode === 200 && authResponse.data?.data) {
        const authData = authResponse.data.data as AuthResponseData;

        if (authData.requires2FA) {
          navigate('/auth/otp', {
            state: { email, password, salt, csrfToken },
          });
          return;
        }

        const verifyTokenResponse = await authenticationApi.verifyToken({
          token: authData.token,
        });
        if (
          verifyTokenResponse.data?.statusCode === 200 &&
          verifyTokenResponse.data?.message === 'Token is valid'
        ) {
          // Update state and storage
          const { token, id, role } = authData;
          set((state) => ({ ...state, token, user: { id, role } }));

          secureLocalStorage.setItem('token', authData.token);
          secureLocalStorage.setItem('refreshToken', authData.refreshToken);
          secureLocalStorage.setItem(
            'user',
            JSON.stringify({ id: authData.id, role: authData.role })
          );
        }

        if (authData.token) {
          notify(translate('Login successful'));
          console.info('Authentication successful');
          navigate(routeByRole(authData.role));
        } else {
          console.error('Authentication failed: Token not found.');
          navigate('/sign-in', { replace: true, state: null });
        }
      } else {
        notifyError(translate('Login failed. Please check your credentials.'));
      }
    } catch (error) {
      notifyError(translate('Login failed. Please check your credentials.'));
      throw error;
    }
  },

  loginWith2FAAction: async (
    email: string,
    password: string,
    otp: number,
    salt: string,
    csrfToken: string,
    recaptchaToken: string,
    translate: (text: string) => string,
    navigate: (route: string, state?: any) => void
  ) => {
    try {
      const authenticationApi = new AuthenticationControllerApi(
        new Configuration({
          basePath: `${process.env.REACT_APP_API_URL}`,
        })
      );
      const response = await authenticationApi.authenticateWithOtp(csrfToken, {
        email,
        password: sha256(password + salt),
        otpCode: otp,
        csrfToken,
        recaptchaToken,
      });

      if (response.data?.statusCode !== 200 && !response.data?.data) {
        notifyError(translate('Login failed. Please check your credentials.'));
        return;
      }
      const authData = response.data.data as AuthResponseData;
      const verifyTokenResponse = await authenticationApi.verifyToken({
        token: authData.token,
      });
      if (
        verifyTokenResponse.data?.statusCode === 200 &&
        verifyTokenResponse.data?.message === 'Token is valid'
      ) {
        const { token, id, role } = authData;
        set((state) => ({ ...state, token, user: { id, role } }));
        secureLocalStorage.setItem('token', authData.token);
        secureLocalStorage.setItem('refreshToken', authData.refreshToken);
        secureLocalStorage.setItem(
          'user',
          JSON.stringify({ id: authData.id, role: authData.role })
        );
      }

      if (authData.token) {
        notify(translate('Login successful'));
        console.info('Authentication successful');
        navigate(routeByRole(authData.role));
        return;
      }
      console.error('Authentication failed: Token not found.');
      navigate('/sign-in', { replace: true, state: null });
    } catch (error) {
      notifyError(translate('Login failed. Please check your credentials.'));
      throw error;
    }
  },

  logOut: async (navigate: (route: string, state?: any) => void) => {
    const userControllerApi = new UserControllerApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_API_URL}`,
      })
    );

    userControllerApi
      .logout({
        headers: {
          Authorization: `Bearer ${get().token}`,
        },
      })
      .then((r) => console.log('Logged out', r))
      .catch((e) => console.error('Logout error', e));

    set((state) => ({ ...state, token: null, user: null }));
    secureLocalStorage.removeItem('refreshToken');
    secureLocalStorage.removeItem('token');
    secureLocalStorage.removeItem('user');
    navigate('/auth/', { replace: true, state: null });
  },
}));
