import SingleChat from "./components/SingleChat";
import CardMenu from "components/card/CardMenu";
import { BsPencilSquare } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Chat from "./components/Chat";
import Card from "components/card";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SocketClient from "../../../socket/SocketClient";
import { Configuration, ConversationControllerApi, ResultWithDataObject } from "../../../rest-client-sdk";
import { ApiResponse } from "../../patient/choosePhysician/interfaces";
import { AxiosResponse } from "axios";
import { useAuthStore } from "store/useAuthStore";
import { useNavigate } from "react-router-dom";
import { useSocketClientStore } from "store/useSocketClientStore";

interface SelectedChatUserInfo {
  name: string;
  profilePicture: string;
  status: string;
  conversationId: string;
  id: string;
}

/*
{
  name: "Roberto Michael",
  profilePicture: "path/to/profile.jpg",
  status: "Active",
};
 */

interface SingleChatMessage {
  sender: string;
  message: string;
  time: string;
  type: string;
}
/*
//{ sender: "Roberto", message: "Hi there, How are you? All good?", time: "05:34 PM", type: "sent" },
  //{ sender: "Roberto", message: "I saw an amazing dashboard called Horizon UI Dashboard", time: "09:00 PM", type: "sent" },
  //{ sender: "You", message: "OMG!! It’s so innovative and awesome!", time: "09:00 PM", type: "received" },
 */

const Messages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const auth = useAuthStore();
  const socketClientContext = useSocketClientStore();
  const [open, setOpen] = React.useState(false);
  const [chatData, setChatData] = React.useState([]);
  const [selectedChatUserInfo, setSelectedChatUserInfo] = React.useState<SelectedChatUserInfo | null>(null);
  const [singleChatMessages, setSingleChatMessages] = React.useState<SingleChatMessage[]>([]);

  const conversationControllerApi = new ConversationControllerApi(
    new Configuration({ basePath: process.env.REACT_APP_API_URL })
  );

  React.useEffect(() => {
    if (open && window.innerWidth < 800)
      document.documentElement.style.overflow = "hidden";
    else document.documentElement.style.overflow = "unset";
  });

  const subscribeToChat = (conversationId: string) => {
    socketClientContext.socketClient.socketClient.subscribe(
      `/topic/${conversationId}`,
      (message: any) => {
        const messageBody = JSON.parse(message.body);
        if (
          messageBody.messageType === "CHAT" ||
          messageBody.messageType === "UNSEEN"
        ) {
          const newMessage = {
            active: true,
            conversationId: messageBody.conversationId,
            id: messageBody.id,
            sender: messageBody.senderUsername,
            message: messageBody.content,
            time: messageBody.timestamp,
            type: (messageBody.senderUsername === auth.user.email) ? "sent" : "received",
            //photo: null,
          }
          setSingleChatMessages((prev) => {
            return [...prev, newMessage];
          });
        }
      },
      "CHAT",
      "UNSEEN"
    );
  }

  const fetchSingleChatMessages = async (token: string, conversationId: string) => {
    return conversationControllerApi.getMessageHistoryByConversationId(conversationId, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  const handleFetchSingleChatMessagesApiResponse = (response: AxiosResponse<ResultWithDataObject>) => {
    const responseData = response.data as ResultWithDataObject;
    const data = responseData.data as ApiResponse;

    if (responseData.statusCode === 200 && Array.isArray(data)) {
      setSingleChatMessages(data);
    } else {
      console.error("Unexpected response status:", responseData.statusCode);
    }
  };

  const getSingleChatMessages = async (conversationId: string) => {
    try {
      let response: AxiosResponse<ResultWithDataObject> = await fetchSingleChatMessages(auth.token, conversationId);
      const responseData = response.data as ResultWithDataObject;
      const data = responseData.data as ApiResponse;

      if (responseData.statusCode === 200 && Array.isArray(data)) {
        setSingleChatMessages(data);
      } else if (responseData.statusCode === 401) {
        const newAccessToken = await auth.refreshTokenAction(t, navigate);
        response = await fetchSingleChatMessages(newAccessToken, conversationId);
        handleFetchSingleChatMessagesApiResponse(response);
      } else {
        console.error("Unexpected response status:", responseData.statusCode);
      }
    } catch (error) {
      console.error("Error fetching message history:", error);
    }
  };

  const fetchMessageHistory = async (token: string) => {
    return conversationControllerApi.getMessageHistory({
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const handleFetchMessageHistoryApiResponse = (response: AxiosResponse<ResultWithDataObject>) => {
    const responseData = response.data as ResultWithDataObject;
    const data = responseData.data as ApiResponse;

    if (responseData.statusCode === 200 && Array.isArray(data)) {
      setChatData(data);
    } else {
      console.error("Unexpected response status:", responseData.statusCode);
    }
  };

  const getMessageHistory = async () => {
    try {
      let response: AxiosResponse<ResultWithDataObject> = await fetchMessageHistory(auth.token);
      const responseData = response.data as ResultWithDataObject;
      const data = responseData.data as ApiResponse;

      if (responseData.statusCode === 200 && Array.isArray(data)) {
        setChatData(data);
      } else if (responseData.statusCode === 401) {
        const newAccessToken = await auth.refreshTokenAction(t, navigate);
        response = await fetchMessageHistory(newAccessToken);
        handleFetchMessageHistoryApiResponse(response);
      } else {
        console.error("Unexpected response status:", responseData.statusCode);
      }
    } catch (error) {
      console.error("Error fetching message history:", error);
    }
  };

  useEffect(() => {
    getMessageHistory().then(() => console.log("Message history fetched"));
  }, []);

  React.useEffect(() => {
    //if (!auth.token || socketClientContext.socketClient) return;

    const socketClient = new SocketClient(process.env.REACT_APP_WS_URL, auth.token);
    socketClient.awaitConnect().then(
      () => {
        console.log("Socket connected");
        socketClientContext.setSocketClient({ socketClient });
      },
      (error) => {
        console.error("Socket connection error", error);
      }
    );
  }, []);

  return (
    <div className="mt-[80px] flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full lg:w-[35%]">
        <Card extra={"w-full h-full p-4"}>
          {/* header */}
          <div className="mb-3 flex items-center justify-between">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            <CardMenu vertical />
          </div>
          {/* search */}
          <div className="flex w-full items-center gap-2">
            <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400" />
              </p>
              <input
                type="text"
                placeholder={t("Search...")}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
            <button className="flex items-center justify-center rounded-full bg-brand-500 p-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
              <BsPencilSquare />
            </button>
          </div>
          {/* messages */}
          <div className="mt-4">
            {chatData.map((chat) => (
              <div key={chat.id} onClick={() => {
                console.log("Chat clicked:", chat.id);
                setSelectedChatUserInfo({
                  id: chat.id,
                  conversationId: chat.conversationId,
                  name: chat.name,
                  profilePicture: chat.photo,
                  status: chat.active ? "Active" : "Inactive",
                });
                getSingleChatMessages(chat.conversationId).then(r => console.log("Single chat messages fetched for conversation:", chat.conversationId));
                subscribeToChat(chat.conversationId);
                setOpen(true);
              }}>
                <Chat
                  photo={chat.photo}
                  active={chat.active}
                  name={chat.name}
                  time={chat.time}
                  message={chat.message}
                />
              </div>
            ))}
          </div>
        </Card>
      </div>
      <div className="w-full lg:w-[64.5%]">
        <SingleChat open={open} onClose={() => setOpen(false)} user={selectedChatUserInfo} messages={singleChatMessages} />
      </div>
    </div>
  );
};

export default Messages;
