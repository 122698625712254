import {
  IoCalendarOutline,
  IoChatbubblesOutline,
  IoGridOutline,
  IoLockClosedOutline,
  IoMail,
  IoPersonOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import SignIn from "./views/auth/signIn/SignIn";
import PatientSettings from "./views/patient/settings";
import PhysicianSettings from "./views/physician/settings";
import ProfileOverview from "./views/admin/main/profile/overview";
import PatientMessages from "./views/patient/messages";
import PhysicianMessages from "./views/physician/messages";
import PatientContact from "./views/patient/contact";
import PhysicianContact from "./views/physician/contact";
import PatientDashboard from "./views/patient/dashboard";
import PhysicianDashboard from "./views/physician/dashboard";
import CalendarPage from "./views/physician/calendar";
import PatientsOverview from "./views/physician/patientsOverview";
import PhysicianAppointmentsOverview from "./views/physician/appointmentsOverview";
import PatientDetails from "./views/physician/patientDetails";
import { IoIosPeople } from "react-icons/io";
import { FaFileAlt } from "react-icons/fa";
import SignUp from "./views/auth/signUp/SignUp";
import ForgotPassword from "./views/auth/forgotPassword/ForgotPassword";
import ResetPassword from "views/auth/resetPassword/ResetPassword";
import PatientAppointmentsOverview from "./views/patient/appointmentsOverview";
import PhysicianDetails from "./views/patient/physicianDetails";
import ChoosePhysician from "./views/patient/choosePhysician";
import ScheduleAppointment from "./views/patient/scheduleAppointment";
import Verification from "./views/auth/verification/Verification";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PatientProfile from "views/patient/profile";
import PhysicianProfile from "views/physician/profile";

const routes = [
  {
    name: "Dashboard",
    layout: "/physician",
    path: "/dashboard",
    icon: <IoGridOutline className="h-6 w-6" />,
    component: <PhysicianDashboard />,
    secondary: true,
    showInSidebar: true,
  },
  {
    name: "Calendar",
    layout: "/physician",
    path: "/calendar",
    icon: <IoCalendarOutline className="h-6 w-6" />,
    component: <CalendarPage />,
    showInSidebar: true,
  },
  {
    name: "Appointments",
    layout: "/physician",
    path: "/appointments",
    icon: <FaFileAlt className="h-6 w-6" />,
    component: <PhysicianAppointmentsOverview />,
    showInSidebar: true,
  },
  {
    name: "Patients",
    layout: "/physician",
    path: "/patients",
    icon: <IoIosPeople className="h-6 w-6" />,
    component: <PatientsOverview />,
    showInSidebar: true,
  },
  {
    name: "Patient Details",
    layout: "/physician",
    path: "/patient-details/:patientId",
    icon: <IoPersonOutline className="h-6 w-6" />,
    component: <PatientDetails />,
    showInSidebar: false,
  },
  {
    name: "Messages",
    layout: "/physician",
    path: "/messages",
    icon: <IoMail className="h-6 w-6" />,
    component: <PhysicianMessages />,
    showInSidebar: true,
  },
  {
    name: "Profile",
    layout: "/physician",
    path: "/profile",
    icon: <IoPersonOutline className="h-6 w-6" />,
    component: <PhysicianProfile />,
    showInSidebar: true,
  },
  {
    name: "Settings",
    layout: "/physician",
    path: "/settings",
    icon: <IoSettingsOutline className="h-6 w-6" />,
    component: <PhysicianSettings />,
    showInSidebar: true,
  },
  {
    name: "Contact Us",
    layout: "/physician",
    path: "/contact-us",
    icon: <IoChatbubblesOutline className="h-6 w-6" />,
    component: <PhysicianContact />,
    showInSidebar: true,
  },
  {
    name: "Dashboard",
    layout: "/patient",
    path: "/dashboard",
    icon: <IoGridOutline className="h-6 w-6" />,
    component: <PatientDashboard />,
    secondary: true,
    showInSidebar: true,
  },
  {
    name: "Appointments",
    layout: "/patient",
    path: "/appointments",
    icon: <FaFileAlt className="h-6 w-6" />,
    component: <PatientAppointmentsOverview />,
    showInSidebar: true,
  },
  {
    name: "Physicians",
    layout: "/patient",
    path: "/choose-physician",
    icon: <IoIosPeople className="h-6 w-6" />,
    component: <ChoosePhysician />,
    showInSidebar: true,
  },
  {
    name: "Patient Details",
    layout: "/patient",
    path: "/physician-profile/:physicianId",
    icon: <IoPersonOutline className="h-6 w-6" />,
    component: <PhysicianDetails />,
    showInSidebar: false,
  },
  {
    name: "Schedule Appointment",
    layout: "/patient",
    path: "/schedule-appointment/:physicianId",
    icon: <IoPersonOutline className="h-6 w-6" />,
    component: <ScheduleAppointment />,
    showInSidebar: false,
  },
  {
    name: "Messages",
    layout: "/patient",
    path: "/messages",
    icon: <IoMail className="h-6 w-6" />,
    component: <PatientMessages />,
    showInSidebar: true,
  },
  {
    name: "Profile",
    layout: "/patient",
    path: "/profile",
    icon: <IoPersonOutline className="h-6 w-6" />,
    component: <PatientProfile />,
    showInSidebar: true,
  },
  {
    name: "Settings",
    layout: "/patient",
    path: "/settings",
    icon: <IoSettingsOutline className="h-6 w-6" />,
    component: <PatientSettings />,
    showInSidebar: true,
  },
  {
    name: "Contact Us",
    layout: "/patient",
    path: "/contact-us",
    icon: <IoChatbubblesOutline className="h-6 w-6" />,
    component: <PatientContact />,
    showInSidebar: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <IoLockClosedOutline className="h-6 w-6" />,
    component:
      <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}>
        <SignIn />
      </GoogleReCaptchaProvider>,
    showInSidebar: false,
  },
  {
    name: "OTP",
    layout: "/auth",
    path: "/otp",
    icon: <IoLockClosedOutline className="h-6 w-6" />,
    component:
      <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}>
        <Verification />
      </GoogleReCaptchaProvider>,
    showInSidebar: false,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <IoLockClosedOutline className="h-6 w-6" />,
    component:
      <GoogleReCaptchaProvider reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}>
        <SignUp />
      </GoogleReCaptchaProvider>,
    showInSidebar: false,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <IoLockClosedOutline className="h-6 w-6" />,
    component: <ForgotPassword />,
    showInSidebar: false,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <IoLockClosedOutline className="h-6 w-6" />,
    component: <ResetPassword />,
    showInSidebar: false,
  },
];
export default routes;
