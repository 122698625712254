import PhysicianCard from "components/card/PhysicianCard";
import Search from "./components/Search";
import React, { useEffect, useState } from "react";
import { Configuration, PhysicianControllerApi, ResultWithDataObject } from "../../../rest-client-sdk";
import DoctorStock from "assets/img/profile/avatar1.jpg";
import { ApiResponse, PageableImpl } from "./interfaces";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "store/useAuthStore";
import { useNavigate } from "react-router-dom";

const ChoosePhysician = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const auth = useAuthStore();
    const [physicians, setPhysicians] = useState([]);
    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [language, setLanguage] = useState("");
    const [specialization, setSpecialization] = useState("");

    const physicianControllerApi = new PhysicianControllerApi(
        new Configuration({ basePath: process.env.REACT_APP_API_URL })
    );

    const fetchPhysicians = async (token: string) => {
        return physicianControllerApi.getPhysicianList(new PageableImpl(0, 5),
            {
                name: name || null,
                specialization: specialization || null,
                gender: gender || null,
                language: language || null,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            });
    };

    const handleApiResponse = (response: AxiosResponse<ResultWithDataObject>) => {
        const responseData = response.data as ResultWithDataObject;
        const data = responseData.data as ApiResponse;
        if (responseData.statusCode === 200 && Array.isArray(data.content)) {
            if (data.page.totalElements > 0) {
                setPhysicians(data.content);
            } else {
                setPhysicians([]);
            }
        } else {
            console.error("Unexpected response status:", responseData.statusCode);
        }
    };

    const getPhysiciansList = async () => {
        try {
            let response: AxiosResponse<ResultWithDataObject> = await fetchPhysicians(auth.token);
            const responseData = response.data as ResultWithDataObject;
            const data = responseData.data as ApiResponse;

            if (responseData.statusCode === 200 && Array.isArray(data.content)) {
                console.log("Setting physicians with the length: ", data.content.length)
                if (data.page.totalElements > 0) {
                    setPhysicians(data.content);
                } else {
                    setPhysicians([]);
                }
            } else if (responseData.statusCode === 401) {
                const newAccessToken = await auth.refreshTokenAction(t, navigate);
                response = await fetchPhysicians(newAccessToken);
                handleApiResponse(response);
            } else {
                console.error("Unexpected response status:", responseData.statusCode);
            }
        } catch (error) {
            console.error("Error fetching physicians:", error);
        }
    };

    useEffect(() => {
        getPhysiciansList().then(() => console.log("Physicians data fetched"));
    }, []);

    useEffect(() => {
        getPhysiciansList().then(() => console.log("Physicians data fetched with filters"));
    }, [name, gender, language, specialization]);

    return (
        <div className="mt-3 h-full w-full rounded-[20px]">
            <div className="mt-[70px] w-full">
                <Search name={name} setName={setName} gender={gender} setGender={setGender} language={language} setLanguage={setLanguage} specialization={specialization} setSpecialization={setSpecialization} />
            </div>
            <div className="mt-[38px] grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
                {physicians.map((physician, index) => (
                    <PhysicianCard
                        key={index}
                        physicianId={physician.physicianId}
                        qualifications={physician.qualifications}
                        specialization={physician.specialization}
                        description={physician.description}
                        educationRecord={physician.educationRecord}
                        previousExperience={physician.previousExperience}
                        profilePhotoUrl={physician.profilePhotoUrl || DoctorStock}
                        firstName={physician.firstName}
                        lastName={physician.lastName}
                    />
                ))}
            </div>
        </div>
    );
};

export default ChoosePhysician;
