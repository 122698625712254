import React, { createContext, Context, ReactNode, useContext } from "react";

interface SocketClientContextType {
    socketClient?: any;
    setSocketClient?: (socketClient: any) => void;
}

const SocketContext: Context<SocketClientContextType> = createContext<SocketClientContextType>(null);

interface SocketClientProviderProps {
    children: ReactNode;
}

const SocketClientProvider: React.FC<SocketClientProviderProps> = ({ children }) => {
    const [socketClient, setSocketClient] = React.useState<any>({});

    const contextValue: SocketClientContextType = {
        socketClient,
        setSocketClient,
    };

    return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};

export default SocketClientProvider;

export const useSocketClientProvider = (): SocketClientContextType => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error("useSocketClientProvider must be used within a SocketClientProvider");
    }
    return context;
};
