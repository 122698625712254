import Profile from "./components/Profile";
import WelcomeWidget from "components/card/WelcomeWidget";
import AppointmentAnalytics from "./components/AppointmentAnalytics";
import TodayAppointments from "./components/TodayAppointments";

import { FaStethoscope } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import MiniStatistics from "./components/MiniStatistics";
import { AppointmentControllerApi, Configuration, PhysicianControllerApi, Pageable } from "../../../rest-client-sdk";
import React from "react";
import { useAuthStore } from "store/useAuthStore";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loading from "views/core/components/Loading";
import { notifyError } from "toast";

const Dashboard = () => {
  const auth = useAuthStore();
  const physicianSelfStore = usePhysicianSelfStore();
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [appointments, setAppointments] = React.useState([]);

  let appointmentApi = new AppointmentControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }));

  const getPhysician = async () => {
    try {
      const details = await physicianSelfStore.getPhysicianDetails(auth.token);
      if (physicianSelfStore.getError()) {
        physicianSelfStore.setError(null)
        const token = await auth.refreshTokenAction(t, navigate);
        const details = await physicianSelfStore.getPhysicianDetails(token);
        if (physicianSelfStore.getError()) {
          notifyError(physicianSelfStore.getError());
          navigate("/auth/sign-in")
          return;
        }
        auth.updateUser(details);
        physicianSelfStore.setLoading(false);
        return;
      }
      auth.updateUser(details);
      physicianSelfStore.setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  class PageableImpl implements Pageable {
    constructor(public page: number, public size: number) { }
  }

  const getAppointmentList = async () => {
    try {
      let response = await appointmentApi.listAppointments(new PageableImpl(0, 5), {
        headers: {
          'Authorization': `Bearer ${auth.token}`
        }
      });
      let data = response.data;
      if (data.statusCode === 200) {
        if (Array.isArray(data.data)) {
          setAppointments(data.data);
        } else {
          console.error("Expected data.data to be an array");
        }
        console.log(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getPhysician().then(r => console.log("User data fetched"));
    getAppointmentList().then(r => console.log("Appointment list fetched"));
  }, []);

  return physicianSelfStore.getLoading() ? <Loading /> : (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          <WelcomeWidget
            extra="mb-5"
            icon={<FaStethoscope />}
            title={`Welcome ${auth.user?.firstName}!`}
            descFirst="You have 7 patients remaining today!"
            descSecond="Don't forget to check their files before the meeting."
          />
          {/* overall & Balance */}
          <div className="mb-5 grid grid-cols-6 gap-5">
            <div className="col-span-6 h-full w-full rounded-xl 3xl:col-span-4">
              <TodayAppointments />
            </div>
            <div className="col-span-6 w-full 3xl:col-span-2">
              <AppointmentAnalytics />
              <MiniStatistics
                icon={<MdPerson className="text-4xl" />}
                title="New Patients"
                value="3"
                bgColor={'bg-lightPrimary dark:!bg-navy-700'}
                growth={'+5%'}
                growthColor="text-green-500"
                cardBg="bg-white"
                titleColor="text-gray-600"
                valueColor="text-navy-700 dark:text-white"
                detailColor="text-gray-600"
                iconColor="text-brand-500 dark:text-white"
              />
            </div>
          </div>
        </div>
      </div>

      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

      {/* right section */}
      <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <Profile />
      </div>
    </div>
  );
};

export default Dashboard;
