import React, { useEffect, useState } from "react";
import About from "./components/About";
import { Configuration, PhysicianControllerApi, UpdatePhysicianRequest } from "rest-client-sdk";
import { notify, notifyError } from "toast";
import Languages from "./components/Languages";
import Education from "./components/Education";
import Experience from "./components/Experience";
import Description from "./components/Description";
import Qualifications from "./components/Qualifications";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";
import Loading from "views/core/components/Loading";
import { useAuthStore } from "store/useAuthStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PhysicianProfile: React.FC = () => {
  const auth = useAuthStore();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>("About")
  const physicianStore = usePhysicianSelfStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const menuItems: Record<string, JSX.Element> = {
    "About": <About />,
    "Description": <Description />,
    "Education": <Education />,
    "Experience": <Experience />,
    "Qualifications": <Qualifications />,
    "Languages": <Languages />,
  }

  const updatePhysicianRequest = async (token?: string) => {
    const physicianApi = new PhysicianControllerApi(new Configuration({
      basePath: process.env.REACT_APP_API_URL
    }))

    const dateOfBirth = new Date(auth.user?.dateOfBirth).toISOString().split("T")[0];
    const body: UpdatePhysicianRequest = {
      firstName: auth.user?.firstName,
      lastName: auth.user?.lastName,
      phoneNumber: auth.user?.phoneNumber,
      dateOfBirth,
      specialization: physicianStore.physicianDetails.specialization,
      description: physicianStore.physicianDetails.description,
      educationRecord: JSON.stringify(physicianStore.physicianDetails.education),
      previousExperience: JSON.stringify(physicianStore.physicianDetails.experience),
      languages: JSON.stringify(physicianStore.physicianDetails.languages),
      qualifications: JSON.stringify(physicianStore.physicianDetails.qualifications),
    }

    return physicianApi.updatePhysician(body, {
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`,
      }
    })
  }

  const handleSave = async () => {
    const firstResponse = await updatePhysicianRequest();
    if (firstResponse.data.statusCode === 200) {
      notify("Successfully updated profile details!");
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate)
    const secondResponse = await updatePhysicianRequest(token);
    if (secondResponse.data.statusCode !== 200) {
      notifyError("Something went wrong!")
      return;
    }
    notify("Successfully updated profile details!");
  }

  useEffect(() => {
    const getDetails = async () => {
      physicianStore.setLoading(true);
      const currentToken = auth.token;
      let details = await physicianStore.getPhysicianDetails(currentToken);
      if (!physicianStore.getError()) {
        auth.updateUser(details);
        physicianStore.setLoading(false)
        return;
      }

      const newToken = await auth.refreshTokenAction(t, navigate)
      details = await physicianStore.getPhysicianDetails(newToken);
      if (physicianStore.getError()) {
        console.log("Got in second error")
        physicianStore.setLoading(false)
        console.error("Error while fetching physician details")
        notifyError("Something went wrong")
        return;
      }
      auth.updateUser(details);
      physicianStore.setLoading(false)
    }
    getDetails();
  }, [])

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/5 bg-white shadow-md">
        <ul className="space-y-4 p-4">
          {Object.keys(menuItems).map((item) => (
            <li
              key={item}
              className={`cursor-pointer ${selectedMenuItem === item
                ? "text-blue-500 font-semibold"
                : "text-gray-600"
                }`}
              onClick={() => setSelectedMenuItem(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="w-4/5 p-8">
        <h1 className="mb-4 text-2xl font-semibold text-gray-800">
          {selectedMenuItem}
        </h1>
        <div className="rounded-lg bg-white p-6 shadow-md">
          {physicianStore.loading ? <Loading /> : menuItems[selectedMenuItem]}
        </div>
        <div className="mt-6 text-right">
          <button
            onClick={handleSave}
            className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhysicianProfile;
