import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Configuration, UserControllerApi } from 'rest-client-sdk';
import { useAuthStore } from 'store/useAuthStore';
import { notify, notifyError } from 'toast';
import avatar from "assets/img/avatars/avatar4.png";


const ProfilePictureUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const auth = useAuthStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = new UserControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }))

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);

      // Your profile picture upload API call here
      const firstResponse = await api.uploadProfilePic({
        file: file,
      },
        {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      // Update user state with new profile picture URL
      if (firstResponse.data.statusCode === 200) {
        notify("Profile picture uploaded successfully");
        const url = (firstResponse.data.data as { profilePhotoUrl: string }).profilePhotoUrl
        auth.updateUser({ ...auth.user, profilePhotoUrl: url })
        return;
      }

      const token = auth.refreshTokenAction(t, navigate);
      const secondResponse = await api.uploadProfilePic({
        file: file,
      },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      if (secondResponse.data.statusCode !== 200) {
        notifyError("Something went wrong");
        return;
      }
      const url = (secondResponse.data.data as { profilePhotoUrl: string }).profilePhotoUrl
      auth.updateUser({ ...auth.user, profilePhotoUrl: url })
      notify("Profile picture uploaded successfully");
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 p-4 bg-white rounded-xl shadow-md">
      <div className="relative">
        <img
          src={auth.user?.profilePhotoUrl || avatar}
          alt="Profile"
          className="w-32 h-32 rounded-full object-cover"
        />
        <label
          htmlFor="profile-upload"
          className="absolute bottom-0 right-0 bg-brand-500 text-white p-2 rounded-full cursor-pointer hover:bg-brand-600 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
        </label>
        <input
          id="profile-upload"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleImageUpload}
          disabled={isUploading}
        />
      </div>
      {isUploading && <div className="text-sm text-gray-500">Uploading...</div>}
    </div>
  );
};

export default ProfilePictureUpload;