export const specializations = [
  'Cardiology',
  'Cardiothoracic Surgery',
  'Dermatology',
  'Emergency Medicine (A&E)',
  'Endocrinology',
  'Gastroenterology',
  'General Surgery',
  'Hematology',
  'Infectious Diseases',
  'Internal Medicine',
  'Neonatology',
  'Nephrology',
  'Neurosurgery',
  'Neurology',
  'Obstetrics and Gynecology',
  'Oncology',
  'Oral and Maxillofacial Surgery',
  'Orthopedics',
  'Otolaryngology (ENT)',
  'Pain Management',
  'Pediatrics',
  'Pediatric Surgery',
  'Physical Medicine and Rehabilitation',
  'Plastic and Reconstructive Surgery',
  'Psychiatry',
  'Psychology',
  'Pulmonology/Respiratory Medicine',
  'Rheumatology',
  'Urology',
  'Vascular Surgery',
];
