import { AxiosResponse } from 'axios';
import { PatientSelfDetails } from 'models/patient';
import {
  Configuration,
  PatientControllerApi,
  ResultWithDataObject,
} from 'rest-client-sdk';
import { create } from 'zustand';

interface PatientSelfState {
  patientDetails: PatientSelfDetails | null;
  loading: boolean;
  error: string | null;
  setPatientDetails: (details: Partial<PatientSelfDetails>) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string) => void;
  getPatientDetails: (token: string) => Promise<any>;
  getError: () => string | null;
  getLoading: () => boolean;
}

const handleResponse = (response: AxiosResponse<ResultWithDataObject>) => {
  const medicationsFromRequest =
    (response.data.data as { medications: string }).medications ?? '{}';
  const parsedMedications = JSON.parse(medicationsFromRequest);
  const medicalHistoryFromRequest =
    (response.data.data as { medicalHistory: string }).medicalHistory ?? '{}';
  const parsedMedicalHistory = JSON.parse(medicalHistoryFromRequest);
  const insuranceProviderNameFromRequest = (
    response.data.data as { insuranceProviderName: string }
  ).insuranceProviderName;
  const insurancePolicyNumberFromRequest = (
    response.data.data as { insurancePolicyNumber: string }
  ).insurancePolicyNumber;
  const allergiesFromRequest =
    (response.data.data as { allergies: string }).allergies ?? '[]';
  const parsedAllergies = JSON.parse(allergiesFromRequest);
  const vaccinationsFromRequest =
    (response.data.data as { vaccinations: string }).vaccinations ?? '[]';
  const parsedVaccinations = JSON.parse(vaccinationsFromRequest);
  const lifestyleFromRequest =
    (response.data.data as { lifestyle: string }).lifestyle ?? '[]';
  const parsedLifestyle = JSON.parse(lifestyleFromRequest);
  const idFromRequest = (response.data.data as { id: string }).id ?? '';
  return {
    id: idFromRequest,
    medications: parsedMedications,
    medicalHistory: parsedMedicalHistory,
    insuranceProviderName: insuranceProviderNameFromRequest,
    insurancePolicyNumber: insurancePolicyNumberFromRequest,
    allergies: parsedAllergies,
    vaccinations: parsedVaccinations,
    lifestyleHabits: parsedLifestyle,
  } as PatientSelfDetails;
};

export const usePatientSelfStore = create<PatientSelfState>((set, get) => ({
  patientDetails: null,
  loading: true,
  error: null,
  setPatientDetails: (details) =>
    set((state) => ({
      patientDetails: { ...state.patientDetails, ...details }, // Merge existing details with the updated ones
    })),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  getPatientDetails: async (token) => {
    const api = new PatientControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_API_URL,
      })
    );
    const response = await api.getPatientDetails({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.statusCode === 200) {
      set({ patientDetails: handleResponse(response) });
      return response.data.data;
    }
    set({ error: 'Something went wrong' });
  },
  getError: () => get().error,
  getLoading: () => get().loading,
}));
