import InputField from "components/fields/InputField";
import { useEffect } from "react";
import { useAuthStore } from "store/useAuthStore";
import ProfilePictureUpload from "views/core/components/ProfilePictureUpload";

const About: React.FC = () => {
  const auth = useAuthStore();
  useEffect(() => {
    (document.getElementById("firstName") as HTMLInputElement).value = auth.user?.firstName;
    (document.getElementById("lastName") as HTMLInputElement).value = auth.user?.lastName;
    (document.getElementById("phoneNumber") as HTMLInputElement).value = auth.user?.phoneNumber;
    (document.getElementById("dob") as HTMLInputElement).value = new Date(auth.user?.dateOfBirth).toISOString().split("T")[0];
  }, [auth])
  return (
    <div className="grid grid-cols-2 gap-6">
      <div>
        <InputField variant="patient" extra="mb-3" id="firstName" label="Legal First Name" placeholder="First Name" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ firstName: e.target.value }) }} />
      </div>
      <div>
        <InputField variant="patient" extra="mb-3" id="lastName" label="Legal Last Name" placeholder="Last Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ lastName: e.target.value }) }} />
      </div>
      <div>
        <InputField variant="patient" extra="mb-3" id="phoneNumber" label="Phone Number" placeholder="555-555-5555"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ phoneNumber: e.target.value }) }} />
      </div>
      <div>
        <InputField
          variant="patient"
          extra="mb-3"
          label={('Date of Birth')}
          placeholder="MM/DD/YYYY"
          id="dob"
          type="date"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ dateOfBirth: e.target.value }) }}
        />
      </div>
      <div className="col-span-2">
        <ProfilePictureUpload />
      </div>
    </div>
  );
};

export default About;