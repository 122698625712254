import React, { useState } from "react";
import { ArrayManagerProps } from "../types";
import { usePatientSelfStore } from "store/usePatientSelfStore";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";

const ArrayManager: React.FC<ArrayManagerProps> = ({ title }) => {
  const [newItem, setNewItem] = useState("");
  let items: string[] = [];
  let setItems = (items: string[]) => console.log("Something went wrong")

  const { patientDetails, setPatientDetails } = usePatientSelfStore()
  const { physicianDetails, setPhysicianDetails } = usePhysicianSelfStore()

  switch (title) {
    case "Allergies":
      items = patientDetails.allergies;
      setItems = (allergies: string[]) => setPatientDetails({ allergies })
      break;
    case "Vaccinations":
      items = patientDetails.vaccinations;
      setItems = (vaccinations: string[]) => setPatientDetails({ vaccinations })
      break;
    case "Lifestyle/Habits":
      items = patientDetails.lifestyleHabits;
      setItems = (lifestyleHabits: string[]) => setPatientDetails({ lifestyleHabits })
      break;
    case "Qualifications":
      items = physicianDetails.qualifications;
      setItems = (qualifications: string[]) => setPhysicianDetails({ qualifications })
      break;
  }

  const handleAdd = () => {
    if (newItem.trim() !== "") {
      setItems([...items, newItem]);
      setNewItem("");
    }
  };

  const handleDelete = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">{title}</h2>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded-md shadow">
            <span>{item}</span>
            <button
              onClick={() => handleDelete(index)}
              className="rounded bg-red-500 px-3 py-1 text-white hover:bg-red-600"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
      <div className="flex items-center space-x-4">
        <input
          type="text"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          placeholder={`Add a ${title.toLowerCase()}...`}
          className="w-full rounded border px-3 py-2 outline-none"
        />
        <button
          onClick={handleAdd}
          className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default ArrayManager;
