import { AxiosResponse } from 'axios';
import { PhysicianSelfDetials } from 'models/physician';
import {
  Configuration,
  PhysicianControllerApi,
  ResultWithDataObject,
} from 'rest-client-sdk';
import { create } from 'zustand';

interface PhysicianSelfState {
  physicianDetails: PhysicianSelfDetials | null;
  loading: boolean;
  error: string | null;
  setPhysicianDetails: (details: Partial<PhysicianSelfDetials>) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string) => void;
  getPhysicianDetails: (token: string) => Promise<any>;
  getError: () => string | null;
  getLoading: () => boolean;
}

const handleResponse = (response: AxiosResponse<ResultWithDataObject>) => {
  const qualificationsFromRequest =
    (response.data.data as { qualifications: string }).qualifications ?? '[]';
  const parsedQualifications = JSON.parse(qualificationsFromRequest);
  const descriptionFromRequest =
    (response.data.data as { description: string }).description ?? '';
  const specializationFromRequest =
    (response.data.data as { specialization: string }).specialization ?? '';
  const experienceFromRequest =
    (response.data.data as { previousExperience: string }).previousExperience ??
    '{}';
  const parsedExperience = JSON.parse(experienceFromRequest);
  const educationFromRequest =
    (response.data.data as { educationRecord: string }).educationRecord ?? '{}';
  const parsedEducation = JSON.parse(educationFromRequest);
  const languagesFromRequest =
    (response.data.data as { languages: string }).languages ?? '{}';
  const parsedLanguages = JSON.parse(languagesFromRequest);
  const idFromRequest = (response.data.data as { id: string }).id ?? '';

  return {
    id: idFromRequest,
    qualifications: parsedQualifications,
    description: descriptionFromRequest,
    experience: parsedExperience,
    education: parsedEducation,
    languages: parsedLanguages,
    specialization: specializationFromRequest,
  } as PhysicianSelfDetials;
};

export const usePhysicianSelfStore = create<PhysicianSelfState>((set, get) => ({
  physicianDetails: null,
  loading: true,
  error: null,
  setPhysicianDetails: (details) =>
    set((state) => ({
      physicianDetails: { ...state.physicianDetails, ...details }, // Merge existing details with the updated ones
    })),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  getPhysicianDetails: async (token) => {
    const api = new PhysicianControllerApi(
      new Configuration({
        basePath: process.env.REACT_APP_API_URL,
      })
    );
    const response = await api.getPhysicianDetails({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.statusCode === 200) {
      set({ physicianDetails: { ...handleResponse(response) }, error: null });
      return response.data.data;
    }
    set({
      error: 'Something went wrong',
    });
    console.log('error from getPhysicianDetails', get().error);
  },
  getError: () => get().error,
  getLoading: () => get().loading,
}));
