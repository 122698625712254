import { AppointmentControllerApi, Configuration, CreateAppointmentRequest } from "../../../../rest-client-sdk";
import { useParams } from "react-router-dom";
import { Slot } from "../interfaces";
import { notify, notifyError } from "../../../../toast";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "store/useAuthStore";

const Content = (props: {
  slots: Slot[]
}) => {
  const { slots } = props;

  const { physicianId } = useParams();

  const { t } = useTranslation();

  const auth = useAuthStore();

  const appointmentControllerApi = new AppointmentControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }));

  // Grouping slots by date
  const groupedSlots = slots.reduce((acc: { [key: string]: Slot[] }, slot) => {
    const date = new Date(slot.startTime).toLocaleDateString(); // Get only the date part
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(slot);
    return acc;
  }, {});

  const getAppointment = async (appointmentTime: string, duration: number) => {
    try {
      let createAppointmentRequest: CreateAppointmentRequest = { title: "Appointment", description: "Description", notes: "Notes", participantId: physicianId, appointmentTime: appointmentTime, duration: duration, procedureId: "procedureId" };
      let response = await appointmentControllerApi.createNewAppointment(createAppointmentRequest, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      if (response.status === 201) {
        notify(t("Appointment created successfully"));
      } else {
        notifyError(t("Failed to create appointment"));
      }
    } catch (e) {
      notifyError(e as string);
    }
  }

  return (
    <div className="h-full w-full rounded-[20px] bg-white pb-8 font-dm dark:!bg-navy-800">
      <div className="flex w-full flex-col p-7 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        {slots.length === 0 ?
          <h2 className="text-xl font-bold text-navy-700 dark:text-white">There is no available slot</h2> : null
        }

        {Object.entries(groupedSlots).map(([date, slots]) => (
          <div key={date} className="mb-4">
            <h3 className="text-lg font-semibold text-gray-600">{date}</h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {slots.map((slot, index) => (
                <button
                  key={index}
                  onClick={() => getAppointment(slot.startTime, new Date(slot.startTime).getTime() - new Date(slot.endTime).getTime())}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600">
                  {new Date(slot.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;