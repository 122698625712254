import React, { useEffect } from "react";
import { Button, Stack } from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import TextField from "components/fields/TextField"
import { IoMail } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { notify, notifyError } from "../../../../toast";
import { Configuration, ContactUsRequest, NotificationControllerApi } from "rest-client-sdk";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "store/useAuthStore";

const ContactUs = () => {
  const { t } = useTranslation();
  const auth = useAuthStore()

  const api = new NotificationControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL
  }))
  const navigate = useNavigate()

  useEffect(() => {
    const nameInput = document.getElementById("name") as HTMLInputElement;
    const emailInput = document.getElementById("mail") as HTMLInputElement;

    if (nameInput && emailInput && auth.user) {
      nameInput.value = `${auth.user.firstName} ${auth.user.lastName}`;
      emailInput.value = auth.user.email;
    }
  }, []);


  const handleSubmit = async () => {
    const message = (document.getElementById("message") as HTMLInputElement).value;
    if (message === "") {
      notifyError("Message field cannot be empty");
      return;
    }


    const firstResponse = await sendContactUsRequest();
    if (firstResponse.status === 200 && firstResponse.data.statusCode === 200) {
      notify(t("Message sent successfully"));
      navigate("/physician/dashboard");
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate)
    const secondResponse = await sendContactUsRequest(token);
    if (secondResponse.status !== 200 && secondResponse.data.statusCode !== 200) {
      notifyError(t("Something went wrong"));
      return;
    }
    notify(t("Message sent successfully"));
    navigate("/physician/dashboard")
  }

  const sendContactUsRequest = async (token?: string) => {
    const name = (document.getElementById("name") as HTMLInputElement).value;
    const email = (document.getElementById("email") as HTMLInputElement).value;
    const message = (document.getElementById("message") as HTMLInputElement).value;

    const body: ContactUsRequest = {
      message: message,
      name: name,
      email: email,
    }

    return api.sendContactUsEmail(body, {
      headers: {
        Authorization: `Bearer ${token ?? auth.token}`
      }
    });
  }

  return (
    <div
      className={`h-full w-full rounded-[20px] bg-white pb-8 font-dm dark:!bg-navy-800`}
    >

      <Stack marginStart={32} marginEnd={32} spacing={21}>
        <InputField
          id="name"
          label={t("Name")}
          placeholder={t("Name")}
        />
        <InputField
          id="mail"
          label={t("Mail")}
          placeholder={t("Mail")}
        />
        <TextField
          id="message"
          label={t("Message")}
          placeholder={t("Message")}
          cols={30}
          rows={5}
        />
        <Button leftIcon={<IoMail />} variant="outline" onClick={handleSubmit} className="linear flex items-center justify-center rounded-[20px] border px-5 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-lightPrimary active:bg-gray-200 dark:!border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          {t("Send Message")}
        </Button>
      </Stack>

    </div>
  );
};

export default ContactUs;
