import ArrayManager from "../../../core/components/ArrayManager";

const Lifestyle: React.FC = () => {
  return (
    <div>
      <ArrayManager
        title="Lifestyle/Habits"
      />
    </div>
  );
};

export default Lifestyle;
