import React, { useEffect, useState } from "react";
import About from "./components/About";
import Medications from "./components/Medications";
import MedicalHistory from "./components/MedicalHistory";
import Insurance from "./components/Insurance";
import { Configuration, PatientControllerApi } from "rest-client-sdk";
import { notify, notifyError } from "toast";
import Lifestyle from "./components/Lifestyle";
import Allergies from "./components/Allergies";
import Vaccinations from "./components/Vaccinations";
import { usePatientSelfStore } from "store/usePatientSelfStore";
import { useAuthStore } from "store/useAuthStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loading from "views/core/components/Loading";

const PatientProfile: React.FC = () => {
  const auth = useAuthStore();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>("About")
  const { patientDetails, setLoading, getPatientDetails, error, loading } = usePatientSelfStore();
  const { t } = useTranslation()
  const navigate = useNavigate()

  const menuItems: Record<string, JSX.Element> = {
    "About": <About />,
    "Medications": <Medications />,
    "Medical History": <MedicalHistory />,
    "Insurance": <Insurance />,
    "Lifestyle/Habits": <Lifestyle />,
    "Allergies": <Allergies />,
    "Vaccinations": <Vaccinations />
  }

  const handleSave = async () => {
    const patientApi = new PatientControllerApi(new Configuration({
      basePath: process.env.REACT_APP_API_URL
    }))
    const dateOfBirth = new Date(auth.user?.dateOfBirth).toISOString().split("T")[0]
    const firstResponse = await patientApi.updatePatient(
      {
        firstName: auth.user?.firstName,
        lastName: auth.user?.lastName,
        phoneNumber: auth.user?.phoneNumber,
        dateOfBirth: dateOfBirth,
        medications: JSON.stringify(patientDetails.medications),
        medicalHistory: JSON.stringify(patientDetails.medicalHistory),
        insurancePolicyNumber: patientDetails.insurancePolicyNumber,
        insuranceProviderName: patientDetails.insuranceProviderName,
        lifestyle: JSON.stringify(patientDetails.lifestyleHabits),
        allergies: JSON.stringify(patientDetails.allergies),
        vaccinations: JSON.stringify(patientDetails.vaccinations)
      },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      })
    if (firstResponse.data.statusCode === 200) {
      notify("Successfully updated profile details!");
      return;
    }

    const token = await auth.refreshTokenAction(t, navigate)

    const secondResponse = await patientApi.updatePatient(
      {
        firstName: auth.user?.firstName,
        lastName: auth.user?.lastName,
        phoneNumber: auth.user?.phoneNumber,
        dateOfBirth: dateOfBirth,
        medications: JSON.stringify(patientDetails.medications),
        medicalHistory: JSON.stringify(patientDetails.medicalHistory),
        insurancePolicyNumber: patientDetails.insurancePolicyNumber,
        insuranceProviderName: patientDetails.insuranceProviderName,
        lifestyle: JSON.stringify(patientDetails.lifestyleHabits),
        allergies: JSON.stringify(patientDetails.allergies),
        vaccinations: JSON.stringify(patientDetails.vaccinations)
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    if (secondResponse.data.statusCode !== 200) {
      console.log(secondResponse.data.message);
      notifyError("Something went wrong!")
      return;
    }
    notify("Successfully updated profile details!");
  }

  useEffect(() => {
    const getDetails = async () => {
      const currentToken = auth.token;
      let details = await getPatientDetails(currentToken);
      if (!error) {
        auth.updateUser(details);
        setLoading(false)
        return;
      }

      const newToken = await auth.refreshTokenAction(t, navigate)
      await getPatientDetails(newToken);
      if (error) {
        setLoading(false);
        console.error("Error while fetching patient details")
        notifyError("Something went wrong")
        return;
      }
      auth.updateUser(details);
      setLoading(false)
    }
    getDetails()

  }, [])

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/5 bg-white shadow-md">
        <ul className="space-y-4 p-4">
          {Object.keys(menuItems).map((item) => (
            <li
              key={item}
              className={`cursor-pointer ${selectedMenuItem === item
                ? "text-blue-500 font-semibold"
                : "text-gray-600"
                }`}
              onClick={() => setSelectedMenuItem(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="w-4/5 p-8">
        <h1 className="mb-4 text-2xl font-semibold text-gray-800">
          {selectedMenuItem}
        </h1>
        <div className="rounded-lg bg-white p-6 shadow-md">
          {loading ? <Loading /> : menuItems[selectedMenuItem]}
        </div>
        <div className="mt-6 text-right">
          <button
            onClick={handleSave}
            className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PatientProfile;
