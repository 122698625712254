import React, { useState } from "react";
import ObjectModal from "../../../core/components/ObjectModal";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";


const Languages: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const { physicianDetails: { languages }, setPhysicianDetails } = usePhysicianSelfStore()

  const handleAddLanguage = () => {
    setIsModalOpen(true);
  };

  const handleDelete = (language: string) => {
    const newLanguages = { ...languages };
    delete newLanguages[language];
    setPhysicianDetails({ languages: newLanguages });

  };

  return (
    <div>
      <div className="space-y-4">
        {Object.entries(languages).map(([language, level]) => (
          <div key={language} className="flex items-center space-x-4">
            <div className="flex-grow w-1/3">
              <span>{language}</span> {/* Display the language name */}
            </div>

            <div className="flex-grow w-2/3">
              <span>{level}</span> {/* Display the level */}
            </div>

            <button
              onClick={() => handleDelete(language)}
              className="mt-2 rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600 flex-shrink-0"
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleAddLanguage}
          className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
        >
          + Add Language
        </button>
      </div>

      {/* Language Modal */}
      <ObjectModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal when Cancel or Save
        keyType="language"
        valueType="profiency"
        title="Languages"
      />
    </div>
  );
};

export default Languages;
