import { Client, IMessage, StompHeaders } from "@stomp/stompjs";

interface PublishOptions {
    destination: string;
    body: any;
}

interface AwaitConnectConfig {
    retries?: number;
    curr?: number;
    timeinterval?: number;
}

type MessageCallback = (message: IMessage) => void;

class SocketClient {
    private client: Client;
    private _jwt: string;
    private url: string;

    constructor(url: string, jwt: string) {
        this.url = url;
        this._jwt = jwt;
        this.client = new Client();

        this.client.configure({
            brokerURL: url,
            connectHeaders: {
                Authorization: `Bearer ${jwt}`,
            },
            onConnect: () => {
                console.log("connected!");
            },
        });

        this.client.activate();
    }

    publish({ destination, body }: PublishOptions): void {
        this.client.publish({
            destination,
            body: JSON.stringify(body),
        });
    }

    deactivate(): void {
        this.client.deactivate();
    }

    subscribe(topic: string, callback: MessageCallback, ...forMessageTypes: string[]): () => void {
        const subscription = this.client.subscribe(topic, (message: IMessage) => {
            const parsedMessage = JSON.parse(message.body);
            if (!forMessageTypes.length || forMessageTypes.includes(parsedMessage.messageType)) {
                callback(message);
            }
        });
        return () => subscription.unsubscribe();
    }

    async awaitConnect(awaitConnectConfig?: AwaitConnectConfig): Promise<void> {
        const { retries = 3, curr = 0, timeinterval = 100 } = awaitConnectConfig || {};

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (this.connected) {
                    resolve();
                } else {
                    console.log("failed to connect! retrying");
                    if (curr >= retries) {
                        console.log("failed to connect within the specified time interval");
                        reject(new Error("Failed to connect within the specified time interval"));
                    } else {
                        this.awaitConnect({ ...awaitConnectConfig, curr: curr + 1 }).then(resolve).catch(reject);
                    }
                }
            }, timeinterval);
        });
    }

    get connected(): boolean {
        return this.client.connected;
    }

    get jwt(): string {
        return this._jwt;
    }

    set jwt(value: string) {
        this._jwt = value;
        this.client.configure({
            connectHeaders: {
                Authorization: `Bearer ${this._jwt}`,
            },
        });
    }
}

export default SocketClient;
