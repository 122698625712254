import ArrayManager from "../../../core/components/ArrayManager";

const Allergies: React.FC = () => {

  return (
    <div>
      <ArrayManager
        title="Allergies"
      />
    </div>
  );
};

export default Allergies;
