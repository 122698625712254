import Default from "layouts/auth/types/Default";
import PinInput from "react-pin-input";
import { useLocation, useNavigate } from "react-router-dom";
import { notify, notifyError } from "../../../toast";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/language/LanguageSelector";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAuthStore } from "store/useAuthStore";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";
import { usePatientSelfStore } from "store/usePatientSelfStore";
function Verification() {
    const { t } = useTranslation();
    const auth = useAuthStore();
    const location = useLocation();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const patientSelfStore = usePatientSelfStore();
    const physicianSelfStore = usePhysicianSelfStore();

    // Destructure state to retrieve passed data
    const { email, password, salt, csrfToken } = location.state || {};

    const [otp, setOtp] = useState<number>(-1);

    // Redirect to login if state is missing
    if (!email || !password || !csrfToken) {
        console.warn("Missing state data. Redirecting to login...");
        notify(t("Missing state data. Redirecting to login..."));
        navigate("/auth/login", { replace: true, state: null });
        return null; // Prevent rendering of the 2FA page
    }

    async function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        if (otp === -1 || otp.toString().length !== 6) {
            notify(t('Please fill all the fields')!);
            return;
        }

        try {
            // Generate reCAPTCHA token
            if (!executeRecaptcha) {
                console.error("reCAPTCHA is not ready");
                notifyError(t("reCAPTCHA is not ready"));
                return;
            }

            executeRecaptcha("signup").then((recaptchaToken) => {
                auth.loginWith2FAAction(email, password, otp, salt, csrfToken, recaptchaToken, t, navigate).then(() => {
                    if (auth.user?.role === "ROLE_PATIENT") {
                        patientSelfStore.getPatientDetails(auth.token);
                        if (patientSelfStore.error) {
                            notifyError(patientSelfStore.error);
                            navigate("/auth/sign-in");
                            return;
                        }
                    } else if (auth.user?.role === "ROLE_PHYSICIAN") {
                        physicianSelfStore.getPhysicianDetails(auth.token);
                        if (physicianSelfStore.error) {
                            notifyError(physicianSelfStore.error);
                            navigate("/auth/sign-in");
                            return;
                        }
                    }
                });
            });
        } catch (err) {
            console.error(err);
            notify(err instanceof Error ? err.message : "An error occurred during login");
        }
    }

    return (
        <Default
            maincard={
                <div className="mb-16 flex h-full w-full items-center justify-center dark:!bg-navy-900 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    {/* Sign in section */}
                    <div
                        className="mt-[16vh] flex flex-col rounded-[20px] bg-white pb-16 pt-12 dark:!bg-navy-900 md:pr-6 lg:pr-0 xl:pr-24">
                        <div className="flex items-center justify-between">
                            <h2 className="mt-1 text-4xl font-bold text-navy-700 dark:text-white">
                                {t('2-Factor Authentication')}
                            </h2>
                            <LanguageSelector />
                        </div>
                        <p className="mt-[15px] ml-1 text-[15px] text-gray-600">
                            {t('Enter the 6-digit code generated by your app to confirm your login')}
                        </p>
                        {/* verification */}
                        <div className="mt-7 mb-4 flex w-full items-center justify-center">
                            <PinInput
                                length={6}
                                initialValue=""
                                onChange={(value, index) => {
                                    setOtp(Number(value))
                                }}
                                type="numeric"
                                inputMode="number"
                                style={{ marginBottom: "10px" }}
                                inputStyle={{
                                    borderWidth: "2px",
                                    width: "92px",
                                    height: "92px",
                                    borderColor: "#E0E5F2",
                                    borderRadius: "16px",
                                    color: "#2B3674",
                                    fontSize: "36px",
                                }}
                                inputFocusStyle={{ borderColor: "#4318FF", borderWidth: "3px" }}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                onComplete={(value) => setOtp(Number(value))}
                            />
                        </div>
                        {/* button */}
                        <button onClick={handleSubmit}
                            className="linear h-[50px] w-full rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                            {t('Verify')}
                        </button>
                    </div>
                </div>
            }
        />
    );
}

export default Verification;
