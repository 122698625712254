import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import 'react-toastify/dist/ReactToastify.css';
import LanguageSelector from "../../../components/language/LanguageSelector";
import { useTranslation } from "react-i18next";
import { notify, notifyError } from "../../../toast";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAuthStore } from "store/useAuthStore";
import { useNavigate } from "react-router-dom";
import { usePatientSelfStore } from "store/usePatientSelfStore";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const auth = useAuthStore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const patientSelfStore = usePatientSelfStore();
  const physicianSelfStore = usePhysicianSelfStore();

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    if (email === "" || password === "") {
      notify(t('Please fill all the fields')!);
      return;
    }

    // Generate reCAPTCHA token
    if (!executeRecaptcha) {
      console.error("reCAPTCHA is not ready");
      notifyError(t("reCAPTCHA is not ready"));
      return;
    }

    try {
      executeRecaptcha("signup").then((recaptchaToken) => {
        auth.loginAction(email, password, recaptchaToken, t, navigate).then(() => {
          if (auth.user?.role === "ROLE_PATIENT") {
            patientSelfStore.getPatientDetails(auth.token);
            if (patientSelfStore.error) {
              notifyError(patientSelfStore.error);
              navigate("/auth/sign-in");
              return;
            }
          } else if (auth.user?.role === "ROLE_PHYSICIAN") {
            physicianSelfStore.getPhysicianDetails(auth.token);
            if (physicianSelfStore.error) {
              notifyError(physicianSelfStore.error);
              navigate("/auth/sign-in");
              return;
            }
          }
        });
      });
    } catch (err) {
      console.error(err);
      notify(err instanceof Error ? err.message : "An error occurred during login");
    }
  }

  return (
    <Default
      maincard={
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <div className="flex items-center justify-between">
              <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                {t('Sign In')}
              </h3>
              <LanguageSelector />
            </div>
            <p className="mb-9 ml-1 text-base text-gray-600">
              {t('Enter your email and password to sign in!')}
            </p>


            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Email')}
              placeholder="mail@heuplus.io"
              id="email"
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Password')}
              placeholder={t('Min. 8 characters')}
              id="password"
              type="password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            <div className="mb-4 flex items-center justify-between px-2">
              <div className="mt-2 flex items-center">
                {/* Checkbox component can be added here if needed */}
              </div>
              <a
                className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                href="/auth/forgot-password"
              >
                {t('Forgot Password?')}
              </a>
            </div>
            <div className="mb-4 flex items-center justify-between px-2" />
            <button
              id='signin-button'
              onClick={handleSubmit}
              className="linear w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {t('Sign In')}
            </button>
            <div className="mt-4">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                {t('Not registered yet?')}
              </span>
              <a
                href="/auth/sign-up"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                {t('Create an account')}
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignIn;