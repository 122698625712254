import InputField from "components/fields/InputField"; // Import InputField component
import { useEffect } from "react";
import { usePatientSelfStore } from "store/usePatientSelfStore";

const Insurance: React.FC = () => {
  const { patientDetails: { insurancePolicyNumber, insuranceProviderName }, setPatientDetails } = usePatientSelfStore()
  useEffect(() => {
    (document.getElementById("providerName") as HTMLInputElement).value = insuranceProviderName;
    (document.getElementById("policyNumber") as HTMLInputElement).value = insurancePolicyNumber;
  }, [insuranceProviderName, insurancePolicyNumber])
  return (
    <div className="grid grid-cols-2 gap-6">
      <div>
        <InputField
          variant="patient"  // You can change this depending on your use case
          extra="mb-3"
          id="providerName"
          label="Provider Name"
          placeholder="Enter Provider Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPatientDetails({ insuranceProviderName: e.target.value }) }}
        />
      </div>
      <div>
        <InputField
          variant="patient"  // You can change this depending on your use case
          extra="mb-3"
          id="policyNumber"
          label="Policy Number"
          placeholder="Enter Policy Number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPatientDetails({ insurancePolicyNumber: e.target.value }) }}
        />
      </div>
    </div>
  );
};

export default Insurance;
