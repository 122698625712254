import React, { useEffect, useRef } from "react";
import Card from "components/card";
import { BiSearch } from "react-icons/bi";
import CardMenu from "components/card/CardMenu";
import { MdChevronLeft, MdImage, MdOutlineAttachment, MdOutlineTagFaces } from "react-icons/md";
import { IoPaperPlane } from "react-icons/io5";
import { renderThumbMessages, renderTrackMessages, renderViewMessages } from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import SingleMessage from "./SingleMessage";
import avatar1 from "../../../../assets/img/avatars/avatar1.png";
import { useSocketClientStore } from "store/useSocketClientStore";

function SingleChat(props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  variant?: string;
  user: {
    id?: string;
    conversationId?: string;
    name?: string;
    profilePicture?: string;
    status?: string;
  };
  messages: Array<{
    sender: string;
    message: string;
    time: string;
    type: string; // 'sent' | 'received'; // message type
  }>;
}) {
  const { open, onClose, user, messages } = props;
  const [message, setMessage] = React.useState<string>("");

  const socketClientContext = useSocketClientStore();
  const profilePicture = user?.profilePicture || avatar1;

  // Ref for the scrollable container
  const scrollRef = useRef<Scrollbars>(null);

  const sendMessage = () => {
    if (message.trim()) {
      socketClientContext.socketClient.socketClient.publish({
        destination: `/app/chat/sendMessage/${user?.conversationId}`,
        body: {
          messageType: "CHAT",
          content: message,
          receiverId: user?.id,
          receiverUsername: user?.name,
        },
      });
      setMessage("");
    }
  };

  // Scroll to the bottom whenever the messages array changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  }, [messages]);

  return user ? (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col !font-dm transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${open ? "translate-x-[20px] lg:translate-x-[0px]" : "-translate-x-[120%] lg:translate-x-[0px]"
        }`}
    >
      <Card extra={"w-full h-full p-4 !font-dm"}>
        {/* Header */}
        <div className="mb-auto flex items-center justify-between border-b border-gray-200 pb-3 dark:!border-navy-700">
          <div className="flex items-center gap-3">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={"block lg:hidden"}>
                <MdChevronLeft className="h-5 w-5 text-gray-500 dark:text-white" />
              </div>
              <div className="h-[50px] w-[50px] rounded-full">
                <img src={profilePicture} className="h-full w-full rounded-full" alt="User Avatar" />
              </div>
            </div>
            <div>
              <h1 className="font-dm text-[20px] font-bold text-navy-700 dark:text-white">{user?.name}</h1>
              <div className="flex items-center gap-2">
                <div className="h-2 w-2 rounded-full bg-green-600"></div>
                <h4 className="text-base text-navy-700 dark:text-white">{user?.status}</h4>
              </div>
            </div>
          </div>
          <div className="flex">
            <p className="flex w-max items-center text-[24px] hover:cursor-pointer">
              <BiSearch />
            </p>
            <CardMenu vertical />
          </div>
        </div>

        {/* Message content */}
        <div className="relative h-[calc(100%-70px)] pt-2">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              ref={scrollRef}
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible">
                {messages.map((msg, index) => {
                  const date = new Date(msg.time);
                  const hour12 = date.getHours() % 12 || 12; // Converts 0 to 12 for midnight
                  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensures 2 digits
                  const amPm = date.getHours() >= 12 ? 'PM' : 'AM';

                  const clock = `${hour12.toString().padStart(2, '0')}:${minutes} ${amPm}`;

                  if (msg.type === "received") {
                    return (
                      <SingleMessage
                        key={index}
                        message={msg.message}
                        time={clock}
                        extra="!bg-lightPrimary w-full md:w-[480px] rounded-r-xl rounded-bl-xl"
                        text="text-navy-700"
                        timecolor="text-gray-400 dark:text-white"
                      />
                    );
                  } else {
                    return (
                      <div key={index} className="flex flex-col items-end">
                        <SingleMessage
                          key={index}
                          message={msg.message}
                          time={clock}
                          extra="bg-brand-500 w-fit md:w-[480px] rounded-l-xl rounded-br-xl"
                          text="text-white"
                          timecolor="text-white"
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </Scrollbars>
          </div>

          {/* Input section */}
          <div className="mt-6 flex items-center gap-2">
            <div className="flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
              <p className="text-lightSecondary pl-3 text-xl hover:cursor-pointer">
                <MdOutlineTagFaces />
              </p>
              <input
                placeholder="Write your message...."
                className="h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") sendMessage();
                }}
              />
              <div className="flex items-center gap-4">
                <p className="text-lightSecondary text-xl hover:cursor-pointer">
                  <MdOutlineAttachment />
                </p>
                <p className="text-lightSecondary pr-2 text-xl hover:cursor-pointer">
                  <MdImage />
                </p>
              </div>
            </div>

            {/* Send button */}
            <button
              onClick={sendMessage}
              className="linear flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <IoPaperPlane />
            </button>
          </div>
        </div>
      </Card>
    </div>
  ) : (
    <div>Select any chat to get started!</div>
  );
}

export default SingleChat;
