import React from "react";

const Loading: React.FC<{ message?: string }> = ({ message = "Loading..." }) => {
  return (
    <div className="flex m-5 h-full w-full items-center justify-center bg-gray-100">
      <div className="flex flex-col items-center space-y-4">
        <div className="h-12 w-12 animate-spin rounded-full border-4 border-brand-500 border-t-transparent"></div>
        <p className="text-lg font-medium text-gray-700">{message}</p>
      </div>
    </div>
  );
};

export default Loading;
