import ArrayManager from "../../../core/components/ArrayManager";

const Vaccinations: React.FC = () => {

  return (
    <div>
      <ArrayManager
        title="Vaccinations"
      />
    </div>
  );
};

export default Vaccinations;
