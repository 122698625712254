import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { AuthenticationControllerApi, Configuration, UserControllerApi } from "rest-client-sdk";
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notify, notifyError } from "../../../toast";
import { useAuthStore } from "store/useAuthStore";
function ResetPassword() {

  const { t } = useTranslation();
  const api = new UserControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL
  }));
  const authApi = new AuthenticationControllerApi(new Configuration({
    basePath: process.env.REACT_APP_API_URL
  }))
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const auth = useAuthStore();
  const token = queryParams.get("token") ?? auth.token;

  // first check if token present
  if (!token) {
    navigate("/auth/sign-in")
  }

  const verifyToken = async (token: string) => {
    return authApi.verifyToken({ token })
  }

  // verify token's integrity
  verifyToken(token).then((res) => {
    if (res.data.statusCode !== 200) {
      notifyError(res.data.message)
      navigate("/auth/sign-in")
    }
  }).catch(err => {
    notifyError(err.message);
    navigate("/auth/sign-in")
    return;
  })


  const resetPassword = async (password: string, salt: string, token: string) => {
    return api.resetPassword({ password, salt }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;
    const passwordRepeat = (document.getElementById("password-repeat") as HTMLInputElement).value;


    if (passwordRepeat === "" || password === "") {
      notify(t("Please fill all the fields"));
      return;
    }

    // check if password is strong
    if (password.length < 8) {
      notify(t("Password must be at least 8 characters long"));
      return;
    }
    if (password.search(/[a-z]/) < 0) {
      notify(t("Password must contain at least one lowercase letter"));
      return;
    }
    if (password.search(/[A-Z]/) < 0) {
      notify(t("Password must contain at least one uppercase letter"));
      return;
    }
    if (password.search(/[0-9]/) < 0) {
      notify(t("Password must contain at least one digit"));
      return;
    }
    if (password !== passwordRepeat) {
      notify(t("Passwords doesn't match"));
      return;
    }

    const salt = crypto.getRandomValues(new Uint8Array(16))
      .reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
    const hash = sha256(password + salt);

    resetPassword(hash, salt, token).then((res) => {
      if (res.data.statusCode !== 200) {
        notifyError(res.data.message)
        navigate("/auth/sign-in")
        return;
      }
      notify(t("Successfully changed the password, you can sign in now!"))
      navigate("/auth/sign-in")
    }).catch((err) => {
      notifyError(err.message)
      navigate("/auth/sign-in")
      return;
    })
  }



  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24">
            <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
              {t('Reset Password')}
            </h2>
            <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600">
              {t('Please enter your new password.')}
            </p>
            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('New Password')}
              placeholder={t('Min. 8 characters')}
              id="password"
              type="password"
            />
            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label={t('Repeat New Password')}
              placeholder={t('Min. 8 characters')}
              id="password-repeat"
              type="password"
            />
            {/* button */}
            <button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)} className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              {t('Reset Password')}
            </button>
          </div>
        </div>
      }
    />
  );
}

export default ResetPassword;
