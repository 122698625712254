import InputField from "components/fields/InputField";
import { specializations } from "../types";
import { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";
import { useAuthStore } from "store/useAuthStore";
import ProfilePictureUpload from "views/core/components/ProfilePictureUpload";
import Loading from "views/core/components/Loading";

const About: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { physicianDetails: { specialization }, setPhysicianDetails, loading } = usePhysicianSelfStore()
  const auth = useAuthStore()
  useEffect(() => {
    (document.getElementById("firstName") as HTMLInputElement).value = auth.user?.firstName;
    (document.getElementById("lastName") as HTMLInputElement).value = auth.user?.lastName;
    (document.getElementById("phoneNumber") as HTMLInputElement).value = auth.user?.phoneNumber;
    (document.getElementById("dob") as HTMLInputElement).value = new Date(auth.user?.dateOfBirth).toISOString().split("T")[0];
  }, [auth.user])
  const handleSpecializationChange = (spec: string) => {
    setPhysicianDetails({ specialization: spec })
    setIsDropdownOpen(false);
  }
  return loading ? <Loading /> : (
    <div className="grid grid-cols-2 gap-6">
      <div>
        <InputField variant="physician" extra="mb-3" id="firstName" label="Legal First Name" placeholder="First Name" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ firstName: e.target.value }) }} />
      </div>
      <div>
        <InputField variant="physician" extra="mb-3" id="lastName" label="Legal Last Name" placeholder="Last Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ lastName: e.target.value }) }} />
      </div>
      <div>
        <InputField variant="physician" extra="mb-3" id="phoneNumber" label="Phone Number" placeholder="555-555-5555"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ phoneNumber: e.target.value }) }} />
      </div>
      <div>
        <InputField
          variant="physician"
          extra="mb-3"
          label={('Date of Birth')}
          placeholder="MM/DD/YYYY"
          id="dob"
          type="date"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { auth.updateUser({ dateOfBirth: e.target.value }) }}
        />
      </div>
      <div className="col-span-2 flex justify-center">
        <Dropdown
          button={
            <div className="flex space-x-2 items-center">
              <label htmlFor="" className="text-sm font-medium text-gray-700 dark:text-gray-300">Your specialization</label>
              <button className="px-4 py-2 bg-gray-100 text-gray-700 text-sm font-semibold rounded-md hover:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-navy-600 transition-colors"
                onClick={() => setIsDropdownOpen(true)}
              >
                {!specialization || !specializations.includes(specialization) ? "Please choose" : specialization}
              </button>
            </div>
          }
          children={
            isDropdownOpen && (
              <div className="flex flex-col p-2 bg-white dark:bg-navy-700 rounded-md shadow-md">
                {specializations.map((spec) => (
                  <button
                    key={spec}
                    onClick={() => handleSpecializationChange(spec)}
                    className="flex items-center space-x-2 p-2 hover:bg-gray-100 dark:hover:bg-navy-600"
                  >
                    <span>{spec}</span>
                  </button>
                ))}
              </div>
            )
          }
          classNames="top-8 -left-[180px]"
        />
      </div>
      <div className="col-span-2">
        <ProfilePictureUpload />
      </div>
    </div>
  );
};

export default About;