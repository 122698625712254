import React, { useState } from "react";
import ObjectModal from "views/core/components/ObjectModal";
import { usePatientSelfStore } from "store/usePatientSelfStore";


const MedicalHistory: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const { patientDetails: { medicalHistory }, setPatientDetails } = usePatientSelfStore();

  const handleAddMedicalHistoryEvent = () => {
    setIsModalOpen(true); // Show modal when "Add Medication" is clicked
  };

  const handleDelete = (item: string) => {
    const newMedicalHistory = { ...medicalHistory };
    delete newMedicalHistory[item];
    setPatientDetails({ medicalHistory: newMedicalHistory });
  };

  return (
    <div>
      <div className="space-y-4">
        {Object.entries(medicalHistory).map(([item, description]) => (
          <div key={item} className="flex items-center space-x-4">
            <div className="flex-grow w-1/3">
              <span>{item}</span> {/* Display the medicine name */}
            </div>

            <div className="flex-grow w-2/3">
              <span>{description}</span> {/* Display the description */}
            </div>

            <button
              onClick={() => handleDelete(item)}
              className="mt-2 rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600 flex-shrink-0"
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleAddMedicalHistoryEvent}
          className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
        >
          + Add Background Event
        </button>
      </div>

      {/* Medication Modal */}
      <ObjectModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal when Cancel or Save
        keyType={"event"}
        valueType={"description"}
        title={"Medical History"}
      />
    </div>
  );
};

export default MedicalHistory;
