import React, { useContext, createContext, useState, ReactNode, FC } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { sha256 } from 'js-sha256';
import {
  Configuration,
  AuthenticationControllerApi,
  RegisterRequest,
  RegisterRequestGenderEnum, UserControllerApi,
  RegisterRequestRoleEnum,
} from '../rest-client-sdk';
import { notify, notifyError } from "../toast";
import { useTranslation } from "react-i18next";

interface AuthContextType {
  token: string;
  user: any;
  loginAction: (email: string, password: string, recaptchaToken: string) => Promise<void>;
  loginWith2FAAction: (email: string, password: string, otp: number, salt: string, csrfToken: string, recaptchaToken: string) => Promise<void>;
  refreshTokenAction: () => Promise<string>;
  updateUser: (data: any) => void;
  registerAction: (data: any) => Promise<void>;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>(JSON.parse((secureLocalStorage?.getItem("user")?.toString() || '{}')));
  const [token, setToken] = useState<string>(secureLocalStorage.getItem("token")?.toString() || "");
  const [refreshToken, setRefreshToken] = useState<string>(secureLocalStorage.getItem("refreshToken")?.toString() || "");
  const navigate = useNavigate();

  const authenticationApi = new AuthenticationControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }));

  const userControllerApi = new UserControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }));

  interface AuthResponseData {
    refreshToken: string;
    token: string;
    id: string;
    role: string;
    requires2FA: boolean;
  }

  const refreshTokenAction = async () => {
    try {
      // Request a new token using the current refresh token
      const response = await authenticationApi.refreshToken({
        refreshToken: refreshToken,
      });

      if (response.data?.statusCode === 200 && response.data.data) {
        const newTokenData = response.data.data as AuthResponseData;

        // Update state and secure storage with the new tokens
        setToken(newTokenData.token);
        setRefreshToken(newTokenData.refreshToken);
        secureLocalStorage.setItem("token", newTokenData.token);
        secureLocalStorage.setItem("refreshToken", newTokenData.refreshToken);
        return newTokenData.token;
      } else {
        showErrorToast(t("Session expired. Please log in again."));
        logOut(); // Log out if the refresh token is invalid
      }
    } catch (error) {
      console.error("Refresh token error:", error);
      showErrorToast(t("Session expired. Please log in again."));
      logOut(); // Log out if an error occurs
    }
  };

  // Utility functions for common operations
  const handleError = (error: any, defaultMessage: string) => {
    console.error(error?.message || defaultMessage, error);
    showErrorToast(t(defaultMessage));
  };

  const handleSuccessfulLogin = async (authData: AuthResponseData) => {
    // Verify token
    const verifyTokenResponse = await authenticationApi.verifyToken({ token: authData.token });
    if (
      verifyTokenResponse.data?.statusCode === 200 &&
      verifyTokenResponse.data?.message === "Token is valid"
    ) {
      // Update state and storage
      setToken(authData.token);
      setRefreshToken(authData.refreshToken);
      setUser({ id: authData.id, role: authData.role });

      secureLocalStorage.setItem("token", authData.token);
      secureLocalStorage.setItem("refreshToken", authData.refreshToken);
      secureLocalStorage.setItem("user", JSON.stringify({ id: authData.id, role: authData.role }));
    }

    if (authData.token) {
      showInfoToast(t("Login successful"));
      console.info('Authentication successful');
      navigateBasedOnRole(authData.role);
    } else {
      console.error("Authentication failed: Token not found.");
      navigate("/sign-in", { replace: true, state: null });
    }
  };

  // Refactored login with 2FA
  const loginWith2FAAction = async (
    email: string,
    password: string,
    otp: number,
    salt: string,
    csrfToken: string,
    recaptchaToken: string
  ) => {
    try {
      const response = await authenticationApi.authenticateWithOtp(csrfToken, {
        email,
        password: sha256(password + salt),
        otpCode: otp,
        csrfToken,
        recaptchaToken
      });

      if (response.data?.statusCode === 200 && response.data?.data) {
        const authData = response.data.data as AuthResponseData;
        await handleSuccessfulLogin(authData);
      } else {
        handleError(response.data, "Login failed. Please check your credentials.");
      }
    } catch (error) {
      handleError(error, "Login failed. Please check your credentials.");
      throw error;
    }
  };

  // Refactored login action
  const loginAction = async (email: string, password: string, recaptchaToken: string) => {
    try {
      const initiateResponse = await authenticationApi.initiateLogin({ email });
      if (initiateResponse.data?.statusCode !== 200 || !initiateResponse.data?.data) {
        handleError(initiateResponse.data, "Login failed. Please check your credentials.");
        return;
      }

      const { salt, csrfToken } = initiateResponse.data.data;
      const hashedPassword = sha256(password + salt);

      const authResponse = await authenticationApi.authenticate(csrfToken, {
        email,
        password: hashedPassword,
        csrfToken,
        recaptchaToken
      });

      if (authResponse.data?.statusCode === 200 && authResponse.data?.data) {
        const authData = authResponse.data.data as AuthResponseData;

        if (authData.requires2FA) {
          navigate("/auth/otp", {
            state: { email, password, salt, csrfToken },
          });
          return;
        }

        await handleSuccessfulLogin(authData);
      } else {
        handleError(authResponse.data, "Login failed. Please check your credentials.");
      }
    } catch (error) {
      handleError(error, "Login failed. Please check your credentials.");
      throw error;
    }
  };


  const registerAction = async (data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    phoneNumber: string;
    role: string;
    recaptchaToken: string;
  }) => {
    try {
      // Generate salt for password hashing
      const salt = crypto.getRandomValues(new Uint8Array(16))
        .reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');

      const registerRequest: RegisterRequest = {
        email: data.email,
        password: sha256(data.password + salt),
        salt: salt,
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender as RegisterRequestGenderEnum,
        phoneNumber: data.phoneNumber,
        role: data.role as RegisterRequestRoleEnum,
        recaptchaToken: data.recaptchaToken
      };

      const response = await authenticationApi.register(registerRequest);
      console.log('Registration response:', response);

      if (response.data?.statusCode === 200 && response.data?.data) {
        const authData = response.data.data as AuthResponseData;
        setToken(authData.token);
        setRefreshToken(authData.refreshToken);
        setUser({
          id: authData.id,
          role: authData.role,
        });

        secureLocalStorage.setItem("refreshToken", authData.refreshToken);
        secureLocalStorage.setItem("token", authData.token);
        secureLocalStorage.setItem("user", JSON.stringify({
          id: authData.id,
          role: authData.role,
        }));

        showInfoToast("Registration successful");
        navigate(`${authData.role === "ROLE_PATIENT" ? "patient" : "physician"}/dashboard`);
      } else {
        const errorMessage = response.data?.message || 'Registration failed';
        console.error('Registration failed:', errorMessage);
        showErrorToast(t("Registration failed. Please try again."));
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Registration failed. Please try again.';
      console.error(errorMessage);
      showErrorToast(t("Registration failed. Please try again."));
    }
  };


  const logOut = () => {
    userControllerApi.logout({
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(r => console.log("Logged out", r)).catch(e => console.error("Logout error", e));
    setUser(null);
    setToken("");
    secureLocalStorage.removeItem("refreshToken");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");
    navigate("/auth/", { replace: true, state: null });
  };

  const updateUser = (data: any) => {
    let updatedUser = { ...user, ...data };
    setUser(updatedUser);
    secureLocalStorage.setItem("user", JSON.stringify(updatedUser));
  }

  const navigateBasedOnRole = (role: string) => {
    switch (role) {
      case "ROLE_ADMIN":
        navigate("/admin/dashboards", { replace: true, state: null });
        break;
      case "ROLE_PHYSICIAN":
        navigate("/physician/dashboard", { replace: true, state: null });
        break;
      case "ROLE_PATIENT":
        navigate("/patient/dashboard", { replace: true, state: null });
        break;
      case "ROLE_USER":
        navigate("/role/dashboard", { replace: true, state: null });
        break;
      default:
        navigate("/", { replace: true, state: null });
    }
  };

  const showInfoToast = (message: string) => {
    notify(message);
  };

  const showErrorToast = (message: string) => {
    notifyError(message);
  };

  const contextValue: AuthContextType = {
    token,
    user,
    loginAction,
    loginWith2FAAction,
    registerAction,
    refreshTokenAction,
    logOut,
    updateUser
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
