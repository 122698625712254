import React, { useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify, FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import avatar from "assets/img/avatars/avatar4.png";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../language/LanguageSelector";
import { useAuthStore } from "store/useAuthStore";

type NavbarProps = {
    onOpenSidenav: () => void;
    brandText: string;
    secondary?: boolean | string;
    mini?: boolean;
    setMini?: (mini: boolean) => void;
    hovered?: boolean;
    theme?: string;
    setTheme?: (theme: string) => void;
};

const Navbar: React.FC<NavbarProps> = ({ onOpenSidenav, brandText, mini, hovered }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [darkmode, setDarkmode] = useState(document.body.classList.contains("dark"));
    const auth = useAuthStore();
    const userRole = auth.user?.role;

    return (
        <nav
            className={`duration-175 linear fixed top-3 right-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${mini === false
                ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
                : mini === true && hovered === true
                    ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
                    : "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]"
                } p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] xl:top-[20px]`}
        >
            <div className="ml-[6px]">
                <div className="h-6 w-[224px] pt-1">
                    <a className="text-sm font-normal text-navy-700 hover:underline dark:text-white" href=" ">
                        {t('Pages')}<span className="mx-1 text-sm text-navy-700 dark:text-white"> / </span>
                    </a>
                    <Link className="text-sm font-normal capitalize text-navy-700 dark:text-white" to="#">
                        {t(brandText)}
                    </Link>
                </div>
                <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
                    <Link to="#" className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                        {t(brandText)}
                    </Link>
                </p>
            </div>

            <div
                className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
                <div
                    className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                        type="text"
                        placeholder={t('Search...')}
                        className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                </div>
                <span className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
                    onClick={onOpenSidenav}>
                    <FiAlignJustify className="h-5 w-5" />
                </span>

                {/* Dark Mode Toggle */}
                <div
                    className="cursor-pointer text-gray-600"
                    onClick={() => {
                        setDarkmode(!darkmode);
                        document.body.classList.toggle("dark", !darkmode);
                    }}
                >
                    {darkmode ? <RiSunFill className="h-4 w-4" /> : <RiMoonFill className="h-4 w-4" />}
                </div>

                <LanguageSelector />

                {/* Profile & Dropdown */}
                <Dropdown
                    button={<img className="h-10 w-10 rounded-full" src={avatar} alt="Profile" />}
                    children={
                        <div className="flex flex-col w-56 p-4 bg-white dark:bg-navy-700 rounded-md shadow-md">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">👋
                                {t('Hey')}, {auth.user?.firstName}</p>
                            <div className="h-px w-full bg-gray-200 dark:bg-white/20 my-3" />
                            <Link
                                to={userRole === "ROLE_ADMIN" || userRole === "ROLE_PHYSICIAN" ? "/physician/profile" : "/patient/profile"}
                                className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                            >
                                {t('Profile')}
                            </Link>
                            <button
                                onClick={() => auth.logOut(navigate)}
                                className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                            >
                                {t('Log Out')}
                            </button>
                        </div>
                    }
                    classNames="top-8 -left-[180px]"
                />
            </div>
        </nav>
    );
};

export default Navbar;
