import React, { useEffect, useState } from "react";
import Card from "components/card";
import PatientTable from "./components/PatientTable";
import { AxiosResponse } from "axios";
import {
    Configuration,
    PatientControllerApi,
    ResultWithDataObject,
} from "../../../rest-client-sdk";
import { ApiResponse, PageableImpl } from "../../patient/choosePhysician/interfaces";
import { useTranslation } from "react-i18next";
import { Patient, PatientDto } from "./interfaces";
import { useAuthStore } from "store/useAuthStore";
import { useNavigate } from "react-router-dom";

// @ts-ignore
export interface ApiResponseWithContent extends ApiResponse {
    content: PatientDto[];
}

const PatientsOverview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const auth = useAuthStore();
    const [patients, setPatients] = useState<Patient[]>([]);

    const patientControllerApi = new PatientControllerApi(
        new Configuration({ basePath: process.env.REACT_APP_API_URL })
    );

    const fetchPhysicians = async (token: string) => {
        return patientControllerApi.getPatientList(new PageableImpl(0, 5), auth.user?.physicianId, {
            headers: { Authorization: `Bearer ${token}` },
        });
    };

    const transformPatientData = (patients: PatientDto[]): Patient[] => {
        return patients.map((patient) => ({
            name: [
                `${patient.firstName} ${patient.lastName}`,
                patient.profilePhotoUrl || "https://i.ibb.co/zPxBHYv/241143773-8212166459343985239-7834018950652403662-n-1.jpg",
            ],
            phoneNumber: patient.phoneNumber || "",
            email: patient.email || "",
            patientId: patient.patientId || "",
            appointmentCount: patient.appointmentCount || 0,
        }));
    };

    const handleApiResponse = (response: AxiosResponse<ResultWithDataObject>) => {
        const responseData = response.data as ResultWithDataObject;
        const data = responseData.data as ApiResponse;

        if (responseData.statusCode === 200 && Array.isArray(data.content)) {
            setPatients(transformPatientData(data.content as unknown as PatientDto[]));
        } else {
            console.error("Unexpected response status:", responseData.statusCode);
        }
    };

    const getPatientList = async () => {
        try {
            let response = await fetchPhysicians(auth.token);
            const responseData = response.data as ResultWithDataObject;
            const data = responseData.data as ApiResponseWithContent;

            if (responseData.statusCode === 200 && Array.isArray(data.content)) {
                setPatients(transformPatientData(data.content));
            } else if (responseData.statusCode === 401) {
                const newAccessToken = await auth.refreshTokenAction(t, navigate);
                response = await fetchPhysicians(newAccessToken);
                handleApiResponse(response);
            } else {
                console.error("Unexpected response status:", responseData.statusCode);
            }
        } catch (error) {
            console.error("Error fetching physicians:", error);
        }
    };

    useEffect(() => {
        getPatientList().then(() => console.log("Patients data fetched"));
    }, []);

    return (
        <Card extra="w-full h-full mt-3">
            <PatientTable patients={patients} />
        </Card>
    );
};

export default PatientsOverview;
