import React, { useState } from "react";
import ObjectModal from "../../../core/components/ObjectModal";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";


const Experience: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const { physicianDetails: { experience }, setPhysicianDetails } = usePhysicianSelfStore()

  const handleAddExperience = () => {
    setIsModalOpen(true);
  };

  const handleDelete = (record: string) => {
    const newExperience = { ...experience };
    delete newExperience[record];
    setPhysicianDetails({ experience: newExperience });
  };

  return (
    <div>
      <div className="space-y-4">
        {Object.entries(experience).map(([record, description]) => (
          <div key={record} className="flex items-center space-x-4">
            <div className="flex-grow w-1/3">
              <span>{record}</span> {/* Display the education */}
            </div>

            <div className="flex-grow w-2/3">
              <span>{description}</span> {/* Display the level */}
            </div>

            <button
              onClick={() => handleDelete(record)}
              className="mt-2 rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600 flex-shrink-0"
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleAddExperience}
          className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
        >
          + Add Record
        </button>
      </div>

      {/* Language Modal */}
      <ObjectModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal when Cancel or Save
        keyType="record"
        valueType="description"
        title="Experience"
      />
    </div>
  );
};

export default Experience;
