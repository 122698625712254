import React, { useState } from "react";
import { ObjectModalProps } from "../types/index";
import { usePatientSelfStore } from "store/usePatientSelfStore";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";

const ObjectModal: React.FC<ObjectModalProps> = ({ isOpen, onClose, keyType, valueType, title }) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const keyTypeTitled = `${keyType[0].toUpperCase()}${keyType.slice(1).toLowerCase()}`;
  const valueTypeTitled = `${valueType[0].toUpperCase()}${valueType.slice(1).toLowerCase()}`

  let onSave = (key: string, value: string) => console.log("Something went wrong");
  const { patientDetails, setPatientDetails } = usePatientSelfStore()
  const { physicianDetails, setPhysicianDetails } = usePhysicianSelfStore()

  switch (title) {
    case "Medications":
      onSave = (key: string, value: string) => {
        const newMedications = { ...patientDetails.medications, [key]: value };
        setPatientDetails({ medications: newMedications });
      }
      break;
    case "Medical History":
      onSave = (key: string, value: string) => {
        const newMedicalHistory = { ...patientDetails.medicalHistory, [key]: value };
        setPatientDetails({ medicalHistory: newMedicalHistory });
      }
      break;
    case "Experience":
      onSave = (key: string, value: string) => {
        const newExperience = { ...physicianDetails.experience, [key]: value };
        setPhysicianDetails({ experience: newExperience });
      }
      break;
    case "Education":
      onSave = (key: string, value: string) => {
        const newEducation = { ...physicianDetails.education, [key]: value };
        setPhysicianDetails({ education: newEducation });
      }
      break;
    case "Languages":
      onSave = (key: string, value: string) => {
        const newLanguages = { ...physicianDetails.languages, [key]: value };
        setPhysicianDetails({ languages: newLanguages });
      }
      break;
  }

  const handleSave = () => {
    if (key) {
      onSave(key, value);
      setKey("")
      setValue("")
      onClose(); // Close the modal
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/3">
            <h3 className="text-lg font-bold mb-4">Add {keyTypeTitled}</h3>
            <div className="mb-4">
              <label className="block mb-2">{keyTypeTitled}</label>
              <input
                type="text"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                className="w-full p-2 border rounded-md"
                placeholder={`Enter the ${keyType}`}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">{valueTypeTitled}</label>
              <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full p-2 border rounded-md"
                placeholder={`Enter the ${valueType}`}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-green-500 text-white rounded-md"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ObjectModal;
