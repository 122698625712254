import ArrayManager from "../../../core/components/ArrayManager";

const Qualifications: React.FC = () => {
  return (
    <div>
      <ArrayManager
        title="Qualifications"
      />
    </div>
  );
};

export default Qualifications;
