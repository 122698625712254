import TextField from "components/fields/TextField";
import { useEffect } from "react";
import { usePhysicianSelfStore } from "store/usePhysicianSelfStore";

const Description: React.FC = () => {
  const { physicianDetails: { description }, setPhysicianDetails } = usePhysicianSelfStore()
  useEffect(() => {
    (document.getElementById("description") as HTMLTextAreaElement).value = description;
  }, [description])
  return (
    <div>
      <div className="space-y-4">
        <TextField
          label="Summary"
          id="description"
          placeholder="Enter your summary"
          cols={7}
          rows={15}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPhysicianDetails({ description: e.target.value })}
        />
      </div>
    </div>
  );
}

export default Description;