import { create } from 'zustand';

interface SocketClientState {
  socketClient: any | null;
  setSocketClient: (socketClient: any) => void | null;
}

export const useSocketClientStore = create<SocketClientState>((set) => ({
  socketClient: null,
  setSocketClient: (socketClient: any) => {
    set((state) => ({
      ...state,
      socketClient,
    }));
  },
}));
