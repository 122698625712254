import Card from "components/card";
import avatar from "assets/img/avatars/avatar11.png";
import Alerts from "./Alerts";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "store/useAuthStore";

function Profile() {
  const { t } = useTranslation();
  const auth = useAuthStore();

  return (
    <Card extra={"w-full h-fit 3xl:h-full px-3 pt-3 2xl:pb-5"}>
      {/* Header */}
      <div className="flex items-center justify-between px-3 pt-3">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Profile
        </h4>
      </div>
      <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
        {/* Background and profile */}
        <div
          className="relative mt-1 flex h-12 w-full justify-center rounded-xl bg-cover"
        >
          <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
            <img className="h-full w-full rounded-full" src={avatar} alt="" />
          </div>
        </div>

        {/* Name and position */}
        <div className="mt-16 flex flex-col items-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {auth.user?.role === "ROLE_PHYSICIAN" ? "Dr." : ""} {auth.user?.firstName} {auth.user?.lastName}
          </h4>
          {auth.user?.role === "ROLE_PHYSICIAN" ? <h5 className="text-base font-normal text-gray-600">{t(auth.user?.specialization)}</h5> : ""}
        </div>

        {/* Post followers */}
        <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
          <div className="flex flex-col items-center justify-center">
            <p className="text-l font-bold text-gray-700">Overall Rating</p>
            <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
              17
            </h4>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-l font-bold text-gray-700">Total Patients</p>
            <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
              434
            </h4>
          </div>
        </div>
      </Card>

      {/* Alerts list */}
      <Alerts />
    </Card>
  );
}

export default Profile;
